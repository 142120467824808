<template>
  <div class="page-container">
    <!-- Section Expertise Is All You Need Start -->
    <div class="section-wrapper">
      <div class="content-wrapper">
        <Content1 :title="$t('expertise[0].title')"></Content1>
      </div>
    </div>
    <!-- Section Expertise Is All You Need End -->

    <!-- Section Expertise Card Start -->
    <div
      v-intersect.once="onIntersect"
      class="section-wrapper section-expertise-card"
      :class="{ 'pt-0': isMobile }"
    >
      <div class="content-wrapper">
        <v-scale-transition :group="true" tag="div" class="row no-gutters">
          <template v-for="(i, index) in expertiseList">
            <v-col
              v-if="isIntersecting"
              :key="index"
              cols="12"
              md="6"
              class="mb-6 mb-md-12"
              :class="{ 'card-wrapper': !isMobile }"
              :style="`
                  transition-delay: 0.${2 + index}s !important;
                  transition-duration: 1s !important;`"
            >
              <Card1
                :isMobile="isMobile"
                :title="i.title"
                :subtitle="$t(`expertise[1].${i.subtitle}`)"
                :text="$t(`expertise[2].${i.description}`)"
              ></Card1>
            </v-col>
          </template>
        </v-scale-transition>
      </div>
    </div>
    <!-- Section Expertise Card End -->

    <!-- Section Contact Us Now Start -->
    <ContactUsNow></ContactUsNow>
    <!-- Section Contact Us Now End -->
  </div>
</template>

<script>
import Card1 from '@/components/card/Card-1'
import ContactUsNow from '@/components/section/ContactUsNow'

export default {
  name: 'OurExpertise',
  components: {
    Card1,
    ContactUsNow
  },
  data: () => ({
    expertiseList: [
      {
        title: '01',
        subtitle: 1,
        description: 1
      },
      {
        title: '02',
        subtitle: 2,
        description: 2
      },
      {
        title: '03',
        subtitle: 3,
        description: 3
      },
      {
        title: '04',
        subtitle: 4,
        description: 4
      },
      {
        title: '05',
        subtitle: 5,
        description: 5
      }
    ],
    isIntersecting: false
  }),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    }
  },
  methods: {
    onIntersect(entries) {
      let isIntersecting = entries[0].isIntersecting

      if (isIntersecting) {
        this.isIntersecting = isIntersecting
      }
    }
  }
}
</script>

<style lang="scss">
.section-expertise-card {
  .card-wrapper {
    &:nth-child(odd) {
      padding-right: 1.25rem !important;
    }
    &:nth-child(even) {
      padding-left: 1.25rem !important;
    }
  }
}
</style>
