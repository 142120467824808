<template>
  <div :class="{ 'section-wrapper': !isMobile }">
    <div :class="{ 'content-wrapper': !isMobile }">
      <v-img
        :min-height="isMobile ? 277 : 397"
        :src="`${shared.BASE_URL}img/png/bg-contact-us.png`"
      >
        <div class="d-flex justify-center align-center wh-100 px-8">
          <div class="text-center">
            <div class="font-cormorant surface--text text-h4 text-lg-h3">
              {{ $t('contactUsNow.title') }}
            </div>

            <p
              class="surface--text mt-5 mb-10 text-body-2 text-lg-body-1"
              v-html="$t('contactUsNow.description')"
            ></p>

            <div class="text-center pt-md-6">
              <v-btn
                tile
                depressed
                :min-width="isMobile ? 153 : 82"
                :min-height="isMobile ? 39 : 46"
                color="primary"
                class="text-capitalize font-weight-black title--text"
                @click="toContactUs"
                >{{ $t('text.connectNow') }}</v-btn
              >
            </div>
          </div>
        </div>
      </v-img>
    </div>
  </div>
</template>

<script>
import { ROUTE_NAME, SHARED } from '@/constants'

export default {
  name: 'ContactUsNow',
  data: () => ({
    shared: SHARED
  }),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    }
  },
  methods: {
    toContactUs() {
      this.$router.push({ name: ROUTE_NAME.CONTACT_US })
    }
  }
}
</script>

<style lang="scss"></style>
