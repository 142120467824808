<template>
  <v-card
    tile
    flat
    outlined
    elevation="0"
    :min-height="isMobile ? 0 : 440"
    height="100%"
    class="pa-4 pa-md-6"
  >
    <v-card-title
      class="font-cormorant primary--text text-h4 text-md-h3 mb-2"
      >{{ title }}</v-card-title
    >
    <v-card-subtitle
      class="text-body-1 text-md-h6 font-weight-bold title--text"
      >{{ subtitle }}</v-card-subtitle
    >
    <v-card-text class="label--text text-body-2 text-md-body-1" v-html="text">
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Card1',
  props: {
    title: {
      type: String,
      default: '01'
    },
    subtitle: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style></style>
